<template>
  <router-view v-if="apiState === 3"></router-view>
  <loader v-else class="mx-auto"></loader>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import enums from '@main/enums';
import education from '@main/api/education';
import Loader from '../Loader.vue';

export default {
  components: { Loader },
  computed: {
    ...mapState('education', ['apiState']),
  },
  methods: {
    ...mapActions('education', ['loadHeaderQuestionsOptions']),
    ...mapMutations('education', ['setApiState']),
  },
  async mounted() {
    if (this.$route.query.api_key) {
      localStorage.setItem('education_api_key', this.$route.query.api_key);
    }
    education.apiService.defaults.params = {
      api_key: localStorage.getItem('education_api_key'),
    };
    this.setApiState(enums.LOADING);
    try {
      await this.loadHeaderQuestionsOptions();
    } catch (e) {
      console.log(e);
      this.setApiState(enums.ERROR);
    }
    this.setApiState(enums.LOADED);
  },
};
</script>
